
import { defineComponent, PropType, computed } from 'vue';

import { useI18n } from '@/plugins/vue-i18n';

import {
  LevelsBox, LevelValuesMap,
} from '@/domains/multileveltest';
import { IAtom, AtomEntity } from '@/domains/atom';
import {
  IMultileveltestContent,
} from '@/domains/multileveltest/composables/useMultileveltestPlayer';

import LevelsScale from '@/components/ui/LevelsScale';
import TestRules from '@/components/ui/TestRules';
import { isEmpty } from '@/helpers';

export default defineComponent({
  name: 'MultileveltestIntroView',
  components: {
    LevelsScale,
    TestRules,
  },
  props: {
    content: {
      type: Object as PropType<IMultileveltestContent>,
      required: true,
    },
    atom: {
      type: Object as PropType<IAtom>,
      default: () => (new AtomEntity({})),
    },
    isSessionNew: {
      type: Boolean,
      default: false,
    },
    isSessionActive: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const title = computed(() => props.atom.name || t('Assignment.Track.defaultTrackName'));
    const buttonName = computed(() => {
      switch (true) {
        case props.isSessionActive:
          return t('MultileveltestView.continue');
        case props.isSessionNew:
          return t('MultileveltestView.test');
        default:
          return t('MultileveltestView.repeat');
      }
    });
    const buttonDataTestLabel = computed(() => {
      switch (true) {
        case props.isSessionActive:
          return 'continue-test';
        case props.isSessionNew:
          return 'start-test';
        default:
          return 'repeat-test';
      }
    });
    const maxLevelValue = computed(() => LevelValuesMap[props.content.maxLevel] || 0);
    const hasResult = computed(() => !isEmpty(props.content.result));
    const currentLevel = computed(() => {
      return hasResult.value
        ? LevelsBox.find(({ type }) => type === props.content.result.level)
        : LevelsBox[0];
    });

    const onRunSessionHandler = () => {
      emit('session:run');
    };

    return {
      title,
      buttonName,
      buttonDataTestLabel,
      maxLevelValue,
      currentLevel,
      hasResult,
      onRunSessionHandler,
    };
  },
});
