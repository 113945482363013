
import {
  computed,
  defineComponent, onMounted, ref, toRefs, watch,
} from 'vue';

import { useRouter } from 'vue-router/composables';
import { useMultileveltestPlayer } from '@/domains/multileveltest/composables/useMultileveltestPlayer';
import { useCatalogueAtom } from '@/domains/atom/composables';
import { useAssignment } from '@/domains/assignment/composables';
import { useI18n } from '@/plugins/vue-i18n';

import MultileveltestIntroView from '@/views/App/Learning/Atoms/multileveltest/MultileveltestIntroView/MultileveltestIntroView.vue';
import AtomPlayerSidebar from '@/components/ui/AtomSidebar/AtomPlayerSidebar.vue';
import TTBackButton from '@/components/ui/TTBackButton.vue';

import { AtomType } from '@/domains/atom';
import Names from '@/plugins/vue-router/routeNames';

export default defineComponent({
  name: 'MultileveltestIntro',
  components: {
    MultileveltestIntroView,
    AtomPlayerSidebar,
    TTBackButton,
  },
  props: {
    playerSessionId: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const { playerSessionId } = toRefs(props);

    const isComponentLoading = ref(true);

    const { t } = useI18n();
    const router = useRouter();

    const {
      isLoading: isMultileveltestLoading,
      content,
      fetchSession,
      runSession,
      isSessionActive,
      isSessionNew,
      isSessionCompleted,
    } = useMultileveltestPlayer({ playerSessionId }, context);

    const {
      isLoading: isAssignmentLoading,
      catalogueAtomVersionId,
      fetchAssignmentBySession,
    } = useAssignment({ playerSessionId, atomType: AtomType.MULTILEVELTEST });

    // TODO: в будущем брать атом из ручки fetchAssignmentBySession
    const {
      atom,
      isLoading: isAtomLoading,
      fetchAtom,
    } = useCatalogueAtom({ atomId: catalogueAtomVersionId });

    const isLoading = computed(() => (
      isComponentLoading.value
      || isMultileveltestLoading.value
      || isAssignmentLoading.value
      || isAtomLoading.value));

    const step = computed(() => ({
      atomType: AtomType.MULTILEVELTEST,
      name: atom.value?.name,
      isCompleted: isSessionCompleted.value,
      id: atom.value?.id ?? '',
    }));

    const breadcrumbs = computed(() => {
      return [
        {
          text: t('LearningTracks.title'),
          to: {
            name: Names.R_APP_LEARNING_TRACKS,
          },
        },
        {
          text: atom.value.name,
          disabled: true,
        },
      ];
    });

    const init = async () => {
      Promise.all([
        fetchSession(),
        fetchAssignmentBySession()
          .then(fetchAtom),
      ])
        .catch((e: any) => {
          console.error(e);
        })
        .finally(() => {
          isComponentLoading.value = false;
        });
    };
    const onSessionRunHandler = async () => {
      try {
        await runSession();

        if (isSessionActive.value) {
          await router.push({
            name: Names.R_APP_LEARNING_SESSION_PLAYER_MULTILEVELTEST_PLAYER,
            params: {
              playerSessionId: String(playerSessionId.value),
            },
          });
        } else {
          await router.push({
            name: Names.R_APP_LEARNING_SESSION_PLAYER_MULTILEVELTEST_RESULT,
            params: {
              playerSessionId: String(playerSessionId.value),
            },
          });
        }
      } catch (e) {
        console.error(e);
      }
    };

    watch(playerSessionId, async () => init());
    onMounted(async () => init());

    return {
      isLoading,
      content,
      atom,
      isSessionActive,
      isSessionNew,
      isSessionCompleted,
      step,
      breadcrumbs,
      onSessionRunHandler,
    };
  },
});
